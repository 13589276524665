<template>
    <div class="du_modal_input">
      <el-input :placeholder="$t('url_input')" v-model="url" @input="setParentData('url', url)"></el-input>
      <div class="validation" v-if="validation.hasError('url')">
        {{ validation.firstError('url') }}
      </div>
    </div>
</template>

<script>
import detectionValidator from "@/mixins/validators/detectionValidator";
import EventBus from "@/utils/event-bus";
export default {
    name: "DetectionWriteUrlLayout",
    mixins: [detectionValidator],
    components: {},
    inject: ['detectionWriteSetData'],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
          url: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setDetectionImageKey();
    },
    mounted() {
        EventBus.$on('DetectionWriteUrlLayoutValidator', this.runValidator)
    },
    beforeDestroy() {
        EventBus.$off('DetectionWriteUrlLayoutValidator')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setDetectionImageKey() {
            this.detectionImageKey = Number(this.$route.params.d_idx)
            this.setParentData('detectionImageKey', this.detectionImageKey);
        },
        setParentData(key, value) {
            this.detectionWriteSetData(key, value);
        },
        runValidator() {
            this.$validate(['url'])
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>w
